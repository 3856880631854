import { useMain } from '@/contexts/main';
import CommonClears from '@/helpers/clears/common.clear';
import CommonMask from '@/helpers/masks';
import { ClientModel } from '@/models/clientModel';
import { useAppSelector } from '@/redux/hooks';
import UserStorage from '@/storages/user-storage';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

type FormType = {
  handleChange(
    value: string | number,
    fieldName: string,
    typeForm: 'personal' | 'card'
  ): void;
  form: ClientModel;
  error: {
    cpf: string;
    email: string;
    name: string;
    phone: string;
  };
  method: 'card' | 'pix' | '';
};

export default function PersonalData({
  handleChange,
  form,
  error,
  method,
}: FormType) {
  const { loginFields, client } = useAppSelector((state) => state.auth);
  const { loading } = useAppSelector((state) => state.payment);
  const { storageClientPre } = useMain();
  const [isToSave, setIsToSave] = useState(!!storageClientPre);

  useEffect(() => {
    if (form.cpf || form.email || form.name || form.phone) {
      if (isToSave) {
        UserStorage.SetClientPre({ ...form });
      } else {
        UserStorage.RemoveClientPre();
      }
    }
  }, [form, isToSave]);

  return (
    <section className="form">
      <h4 className="title">Dados Pessoais:</h4>
      <TextField
        value={CommonMask.cpf(form.cpf)}
        fullWidth
        error={!!error.cpf}
        helperText={error.cpf}
        className="field"
        label="Informe seu CPF"
        inputMode="numeric"
        required
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        variant="outlined"
        disabled={loading}
        onChange={(event) => {
          if (event.target.value.length <= 14) {
            const value = CommonClears.clearCPF(event.target.value);
            handleChange(value, 'cpf', 'personal');
          }
        }}
      />

      <TextField
        value={form.name}
        required
        error={!!error.name}
        helperText={error.name}
        fullWidth
        className="field"
        label={method === 'card' ? 'Nome completo' : 'Nome'}
        variant="outlined"
        type="text"
        disabled={loading}
        onChange={(event) => {
          const value = event.target.value;
          handleChange(value, 'name', 'personal');
        }}
      />

      {(method === 'card' || client?.config_cliente?.comanda_sem_qrcode) && (
        <TextField
          value={form.email}
          required
          error={!!error.email}
          helperText={error.email}
          fullWidth
          className="field"
          label="Qual seu e-mail"
          inputMode="email"
          inputProps={{
            inputMode: 'email',
          }}
          variant="outlined"
          type="email"
          disabled={loading}
          onChange={(event) => {
            const value = event.target.value;
            handleChange(value, 'email', 'personal');
          }}
        />
      )}

      <TextField
        value={CommonMask.phone(form.phone)}
        required
        error={!!error.phone}
        helperText={error.phone}
        fullWidth
        className="field"
        label="Seu telefone"
        InputLabelProps={form.phone ? { shrink: true } : {}}
        inputMode="numeric"
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        variant="outlined"
        disabled={loading}
        onChange={(event) => {
          if (event.target.value.length <= 15) {
            const value = CommonClears.clearPhone(event.target.value);
            handleChange(value, 'phone', 'personal');
          }
        }}
      />
      {loginFields?.config_qrcode.pre_pago && (
        <FormControlLabel
          className="checkboxLabel"
          control={
            <Checkbox
              className="optionalRadio"
              defaultChecked={!!storageClientPre}
              onChange={(event) => setIsToSave(event.target.checked)}
            />
          }
          label="Salvar dados pessoais para compras futuras."
        />
      )}
    </section>
  );
}
