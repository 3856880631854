import CardIcon from '@/assets/svg/Card';
import PixIcon from '@/assets/svg/Pix';
import FooterBottomSheet from '@/components/FooterBottomSheet';
import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import { useCart } from '@/contexts/cart';
import { useMain } from '@/contexts/main';
import { MakePaymentRequest, MakePaymentSelectItensRequest } from '@/models/makePayment.request';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { PostPayment } from '@/services/payment';
import { Dialog, DialogContent, Radio, RadioGroup, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormCard from './components/FormCard';
import PersonalData from './components/PersonalData';
import { MakeOrderRequest } from '@/models/makeOrder.request';
import { usePages } from '@/contexts/pages';
import CommonValidators from '@/helpers/validators/common.validators';
import UserStorage from '@/storages/user-storage';
import ModalSuccess from '@/components/ModalSucess';
import ModalLoading from '@/components/ModalLoading';
import { GetCustomerData } from '@/services/auth';
import ModalError from '@/components/ModalError';
import { reset } from '@/redux/reducers/payment';
import { TransitionProps } from '@mui/material/transitions';
import { setSelectedItens } from '@/redux/reducers/order';

const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentPage() {
	const { cart } = useCart();
	const dispatch = useAppDispatch();
	const { responseLogin, loginFields, customerData, client } = useAppSelector(state => state.auth);
	const { requestError, loading, responsePayment } = useAppSelector(state => state.payment);
	const { list, selectedItens } = useAppSelector(state => state.order);
	const { storageLogin, storageClientPre } = useMain();
	const personalData = responseLogin || storageLogin;
	const [years, setYears] = useState<number[]>([]);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const [method, setMethod] = useState<'card' | 'pix' | ''>('');
	const { setOpenPayment, openPayment, setOpenSuccessPage } = usePages();
	const [form, setForm] = useState({
		cpf: '',
		name: '',
		email: '',
		phone: '',
	});
	const [error, setError] = useState({
		cpf: '',
		email: '',
		name: '',
		phone: '',
	});
	const [formCard, setFormCard] = useState({
		number: '',
		cvc: '',
		month: '',
		year: '',
	});
	const [cardError, setCardError] = useState({
		number: '',
		cvc: '',
		month: '',
		year: '',
	});
	const [openSuccess, setOpenSuccess] = useState(false);
	const [openLoading, setOpenLoading] = useState(false);

	function fillYears() {
		const currentYear = new Date().getFullYear();
		const newYears = [currentYear];

		for (let index = 1; index < 20; index++) {
			newYears.push(currentYear + index);
		}

		setYears(newYears);
	}

	useEffect(() => {
		fillYears();
	}, []);

	function onDismiss() {
		setOpenPayment(false);
	}

	function handleChange(value: string | number, fieldName: string, typeForm: 'personal' | 'card') {
		if (typeForm === 'personal') {
			setForm({ ...form, [fieldName]: value });
		} else {
			setFormCard({ ...formCard, [fieldName]: value });
		}
	}

	useEffect(() => {
		if (!form.cpf && !form.email && !form.name && !form.phone) {
			if (loginFields && loginFields.config_qrcode.pre_pago) {
				if (storageClientPre) {
					setForm({
						cpf: storageClientPre.cpf || '',
						name: storageClientPre.name || '',
						email: storageClientPre.email || '',
						phone: storageClientPre.phone || '',
					});
				}
			} else if (personalData) {
				setForm({
					cpf: personalData.cliente.cpf || '',
					name: personalData.cliente.nome_completo || '',
					email: personalData.cliente.email || '',
					phone: personalData.cliente.fone || '',
				});
			}
		}
	}, [personalData, storageClientPre, loginFields]);

	useEffect(() => {
		if (requestError) {
			setSnackMsg(requestError);
			setShowSnack(true);
		}
	}, [requestError]);

	function handleCloseAlert() {
		setShowSnack(false);
	}

	function changeMethod(method: 'card' | 'pix') {
		setMethod(method);
	}

	useEffect(() => {
		if (responsePayment) {
			UserStorage.SetOrderId(responsePayment.comanda_id);

			if (responsePayment.chave_pix) {
				onDismiss();
			} else {
				if (responsePayment.retorno && method === 'card') {
					if (client?.config_cliente?.comanda_sem_qrcode) {
						setOpenSuccess(true);
					} else if (loginFields) {
						if (loginFields.config_qrcode.pre_pago) {
							setOpenSuccess(true);
						} else {
							setOpenSuccessPage(true);
						}
						onDismiss();
					} else {
						onDismiss();
					}
				}
			}
		}
	}, [responsePayment]);

	useEffect(() => {
		setOpenLoading(loading);
	}, [loading]);

	async function submitPayment() {
		const cpfValidator = CommonValidators.isCPFValid(form.cpf);
		const emailValidator = method === 'card' || client?.config_cliente?.comanda_sem_qrcode ? CommonValidators.isEmailValid(form.email) : { status: true, error: '' };
		const nameValidator = method === 'card' ? CommonValidators.isNameValid(form.name) : form.name.length < 1 ? { status: false, error: 'Nome obrigatório!' } : { status: true, error: '' };
		const phoneValidator = CommonValidators.isCellphoneValid(form.phone);
		let validCard = true;
		let errorData = [];

		if (!cpfValidator.status) {
			errorData.push('CPF');
		}

		if (!emailValidator.status) {
			errorData.push('e-mail');
		}

		if (!nameValidator.status) {
			errorData.push('nome');
		}

		if (!phoneValidator.status) {
			errorData.push('telefone');
		}

		if (method === 'card') {
			const numberValidator = CommonValidators.isEmptyField(formCard.number, 'número do cartão');
			const monthValidator = CommonValidators.isEmptyField(formCard.month, 'mês');
			const yearValidator = CommonValidators.isEmptyField(formCard.year, 'ano');
			const cvcValidator = CommonValidators.isEmptyField(formCard.cvc, 'cvc');

			setCardError({
				number: numberValidator.error,
				month: monthValidator.error,
				year: yearValidator.error,
				cvc: cvcValidator.error,
			});

			if (!numberValidator.status) {
				errorData.push('número');
			}
			if (!monthValidator.status) {
				errorData.push('mês');
			}
			if (!yearValidator.status) {
				errorData.push('ano');
			}
			if (!cvcValidator.status) {
				errorData.push('validade');
			}

			if (
				+formCard.year < new Date().getFullYear() ||
				+formCard.year > new Date().getFullYear() + 30 ||
				+formCard.month < 1 ||
				+formCard.month > 12 ||
				!numberValidator.status ||
				!monthValidator.status ||
				!yearValidator.status ||
				!cvcValidator.status
			) {
				validCard = false;
			}
		}

		setError({
			cpf: cpfValidator.error,
			email: emailValidator.error,
			name: nameValidator.error,
			phone: phoneValidator.error,
		});

		if (errorData.length > 0) {
			setSnackMsg(`Por favor, insira os dados (${errorData.join(', ')}) corretamente!`);
			setShowSnack(true);
		}

		if (cpfValidator.status && emailValidator.status && nameValidator.status && phoneValidator.status && validCard) {
			const formProducts: MakeOrderRequest['produtos'] = [];

			if ((loginFields && loginFields.config_qrcode.pre_pago) || client?.config_cliente?.comanda_sem_qrcode) {
				cart.items.forEach((product, index) => {
					formProducts.push({
						produto_id: product.id,
						quantidade: product.qtd,
						observacao: product.observation,
						unidade_medida_id: product.idUnity,
						opcionais: [],
					});

					if (product.options) {
						product.options.forEach(option => {
							formProducts[index].opcionais.push({
								id: option.id,
								quantidade: option.qtd,
							});
						});
					}
				});
			}
			let formData: MakePaymentRequest | MakePaymentSelectItensRequest = {} as MakePaymentRequest;
			if (client?.config_cliente?.agrupa_itens_mesa) {
				formData = {
					comanda_id: personalData?.comanda_id || 0,
					qrcode_id: +(loginFields?.config_qrcode.id || 0),
					cliente: {
						cpf: form.cpf,
						email: method === 'card' || client?.config_cliente?.comanda_sem_qrcode ? form.email : '',
						fone: form.phone,
						nome_completo: form.name,
					},
					produtos_pagamento: selectedItens.map(item => {
						return {
							id: +item.id,
						};
					}),
					tipo: 'P',
				};
			} else {
				formData = {
					comanda_id: personalData?.comanda_id || 0,
					qrcode_id: +(loginFields?.config_qrcode.id || 0),
					cliente: {
						cpf: form.cpf,
						email: method === 'card' || client?.config_cliente?.comanda_sem_qrcode ? form.email : '',
						fone: form.phone,
						nome_completo: form.name,
					},
					produtos: formProducts,
					tipo: 'P',
				};
			}

			if (method === 'card') {
				formData.tipo = 'C';
				formData.cartao = {
					cvc: formCard.cvc,
					expiracao: formCard.month + '/' + formCard.year,
					numero_cartao: formCard.number,
					parcelas: '01',
				};
			}
			await dispatch(PostPayment(formData));
		}
	}

	async function getCustomerData(cpf: string) {
		if (cpf) {
			await dispatch(GetCustomerData({ busca: cpf }));
		}
	}

	useEffect(() => {
		if (form.cpf) {
			const cpfValidator = CommonValidators.isCPFValid(form.cpf);
			if (cpfValidator.status) {
				getCustomerData(form.cpf);
			}
		}
	}, [form.cpf]);

	useEffect(() => {
		if (customerData?.conteudo) {
			setForm({
				...form,
				phone: customerData.conteudo.fone || '',
				name: customerData.conteudo.nome || '',
				email: customerData.conteudo.email || '',
			});
		}
	}, [customerData]);

	return (
		<>
			<Dialog fullScreen open={openPayment} onClose={onDismiss} TransitionComponent={Transition} id="payment" scroll="paper" className="customDialog" disableScrollLock>
				<HeaderBottomSheet title="Pagamento" onDismiss={onDismiss} />
				<DialogContent>
					<div className="container">
						<section className="methods">
							<h4 className="title">Meios de pagamento:</h4>
							<RadioGroup value={method}>
								{client?.config_cliente?.formas_pagamento_comanda.filter(paymentOption => paymentOption.nome === 'Cartão de crédito').length != 0 && (
									<div className="methodsItem">
										<div className="methodsHead" onClick={() => changeMethod('card')}>
											<div className="methodsIcon">
												<CardIcon />
											</div>
											<p className="methodsText">Cartão de crédito</p>
											<Radio value="card" className="optionalRadio" />
										</div>
										{method === 'card' && (
											<>
												<PersonalData handleChange={handleChange} form={form} error={error} method={method} />
												<FormCard handleChange={handleChange} formCard={formCard} months={months} years={years} cardError={cardError} setFormCard={setFormCard} />
											</>
										)}
									</div>
								)}
								{client?.config_cliente?.formas_pagamento_comanda.filter(paymentOption => paymentOption.nome === 'Pix').length != 0 && (
									<div className="methodsItem">
										<div className="methodsHead" onClick={() => changeMethod('pix')}>
											<div className="methodsIcon methodsIcon--pix">
												<PixIcon />
											</div>
											<p className="methodsText">Pix</p>
											<Radio value="pix" className="optionalRadio" />
										</div>
										<>{method === 'pix' && <PersonalData handleChange={handleChange} form={form} error={error} method={method} />}</>
									</div>
								)}
							</RadioGroup>
						</section>
					</div>
				</DialogContent>
				<FooterBottomSheet
					btnTxt={`${method === 'pix' ? 'Gerar código para pagamento' : 'Confirmar pagamento'}`}
					tot={(client?.config_cliente.comanda_sem_qrcode || (loginFields && loginFields.config_qrcode.pre_pago) ? cart.tot : list?.conteudo ? list?.conteudo[0].total : 0) || 0}
					qtd={(client?.config_cliente.comanda_sem_qrcode || (loginFields && loginFields.config_qrcode.pre_pago) ? cart.items.length : list?.conteudo ? list?.conteudo[0].produtos.length : 0) || 0}
					disabled={method === '' || loading}
					onClick={() => {
						submitPayment();
					}}
				/>
				<ModalLoading open={openLoading} txt={method === 'card' ? 'Processando pagamento, aguarde por favor...' : 'Gerando código PIX, aguarde por favor...'} />
				<ModalSuccess open={openSuccess} setOpen={setOpenSuccess} />
				<ModalError open={showSnack} setOpen={setShowSnack} errorMsg={snackMsg} reset={reset} />
			</Dialog>
		</>
	);
}
