import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { MakeOrderRequest } from '@/models/makeOrder.request';
import { MakeOrderResponse } from '@/models/makeOrder.response';
import { ListOrderResponse } from '@/models/listOrder.response';

export const PostOrder = createAsyncThunk(
  'order/PostOrder',
  async (request: MakeOrderRequest) => {
    const response = await api
      .post(`/insere-pedido`, request)
      .then((r): MakeOrderResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const ListOrder = createAsyncThunk(
  'order/ListOrder',
  async (orderId: number) => {
    const url = `/lista-pedido/${orderId}`;
    const response = await api
      .get(url)
      .then((r): ListOrderResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
