import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import NotFoundComp from '@/components/NotFound';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import React, { useEffect, useState } from 'react';
import { ListOrder } from '@/services/order';
import QRCode from 'react-qr-code';
import FooterBottomSheet from '@/components/FooterBottomSheet';
import ModalQrCode from '@/components/ModalQrCode';
import { Button, CircularProgress, Dialog, DialogContent, Slide } from '@mui/material';
import { usePages } from '@/contexts/pages';
import { useMain } from '@/contexts/main';
import UserStorage from '@/storages/user-storage';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyItemsPage() {
	const dispatch = useAppDispatch();
	const { client } = useAppSelector((state) => state.auth);
	const { loading, list } = useAppSelector((state) => state.order);
	const { responsePayment } = useAppSelector((state) => state.payment);
	const [openModal, setOpenModal] = useState(false);
	const { setOpenMyItems, openMyItems } = usePages();
	const { qrcode, storageOrderId } = useMain();
	const [selectQrCodeIndex, setSelectQrCodeIndex] = useState(0);
	const [intervalTime, setIntervalTime] = useState<any>(null);

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	async function getOrder(orderId: number) {
		await dispatch(ListOrder(orderId));
	}

	useEffect(() => {
		if (openMyItems) {
			const getOrderId = storageOrderId || UserStorage.GetOrderId(qrcode);
			if (responsePayment) {
				getOrder(responsePayment.comanda_id);
				const interval = setInterval(function () {
					getOrder(responsePayment.comanda_id);
				}, 3000);
				setIntervalTime(interval);
			} else if (getOrderId && client) {
				getOrder(getOrderId);
				const interval = setInterval(function () {
					getOrder(getOrderId);
				}, 3000);
				setIntervalTime(interval);
			}
		} else {
			clearInterval(intervalTime);
		}
	}, [openMyItems]);

	function onDismiss() {
		setOpenMyItems(false);
	}

	return (
		<Dialog fullScreen open={openMyItems} onClose={onDismiss} TransitionComponent={Transition} id="myItems" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title="Meus Itens" onDismiss={onDismiss} />
			<DialogContent>
				<div>
					{loading && !list ? (
						<div className="loadingDefault">
							<CircularProgress className="loading" />
							Estamos carregando seus itens...
						</div>
					) : (
						<div className="content">
							{list?.conteudo && (
								<ul className="infos">
									{list.conteudo[0].nome && <li className="info">Nome: {list.conteudo[0].nome}</li>}
									{list.conteudo[0].nr_comanda && <li className="info">Comanda: {list?.conteudo[0].nr_comanda}</li>}
									{list.conteudo[0].status && <li className="info">Status da Comanda: {list?.conteudo[0].status}</li>}
									{list.conteudo[0].nr_mesa && <li className="info">Mesa: {list?.conteudo[0].nr_mesa}</li>}
									{client?.config_cliente.texto_comanda && (
										<li className="info">
											<br />
											Texto: {client.config_cliente.texto_comanda}
										</li>
									)}
								</ul>
							)}

							{list?.conteudo && list.conteudo[0].produtos.length > 0 ? (
								<div className="list">
									{list.conteudo[0].produtos.find((product) => product.qrcode) && <p className="callList">Apresente o QRCODE para retirar o produto</p>}
									{list.conteudo[0].produtos.map((item, index) => (
										<Button
											className="item"
											key={index}
											onClick={() => {
												if (item.qrcode) {
													setSelectQrCodeIndex(index);
													setOpenModal(true);
												}
											}}>
											<div className="itemImg" style={{ backgroundImage: `url(${item.imagem})` }}></div>
											<div className="itemContent">
												<p className="itemPrice">
													<span>R$:</span> {CommonMask.currency(item.total.toFixed(2).toString())}
												</p>
												<p className="itemTitle">{item.nome}</p>
												{item.adicionais && (
													<p className="itemOptions">
														(
														{item.adicionais.map((option, key) => {
															return option.nome + (item.adicionais && key === item.adicionais.length - 1 ? '' : ', ');
														})}
														)
													</p>
												)}
												{client?.config_cliente.utiliza_senha_integracao && item?.num_cupom_externo && <p className="itemOptions">Senha: {item.num_cupom_externo}</p>}
											</div>
											<p className="itemQtd">Qtd: {item.quantidade}</p>
											{item.qrcode && (
												<div className="boxQrCode">
													<QRCode value={item.qrcode} />
												</div>
											)}
										</Button>
									))}
								</div>
							) : (
								<NotFoundComp txt="Você ainda não adquiriu produtos!" />
							)}
						</div>
					)}
				</div>
			</DialogContent>
			{list?.conteudo && <FooterBottomSheet enableBtn={false} tot={list?.conteudo[0].total || 0} qtd={list?.conteudo[0].produtos.length || 0} />}
			<ModalQrCode open={openModal} handleClose={handleCloseModal} index={selectQrCodeIndex} setSelectIndex={setSelectQrCodeIndex} />
		</Dialog>
	);
}
