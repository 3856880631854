import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { SearchClienteRequest } from '@/models/client.request';
import { SearchClienteResponse } from '@/models/client.response';
import { LoginQrcodeResponse } from '@/models/loginQrcode.response';
import { LoginQrcodeRequest } from '@/models/loginQrcode.request';
import { LoginResponse } from '@/models/login.response';
import { CustomerDataRequest } from '@/models/customerData.request';
import { CustomerDataResponse } from '@/models/customerData.response';
import { SessionStatusRequest } from '@/models/sessionStatus.request';
import { SessionStatusResponse } from '@/models/sessionStatus.response';

export const GetClient = createAsyncThunk('auth/GetClient', async (request: SearchClienteRequest) => {
	const response = await api
		.post(`/busca-cliente`, request, { headers: { 'API-TOKEN': process.env.API_TOKEN || '' } })
		.then((r): SearchClienteResponse => {
			api.defaults.headers['API-TOKEN'] = `${r.data.token}`;
			return r.data;
		})
		.catch(error => {
			return error.response.data;
		});
	return response;
});

export const SetLoggedTicket = createAsyncThunk('auth/setLoggedTicket', async (loggedIn: { loggedTicket: boolean }) => {
	return loggedIn;
});

export const GetCustomerData = createAsyncThunk('auth/GetCustomerData', async (request: CustomerDataRequest) => {
	const response = await api
		.post(`/busca-dados-usuario`, request)
		.then((r): CustomerDataResponse => {
			return r.data;
		})
		.catch(error => {
			return error.response.data;
		});
	return response;
});

export const GetLogin = createAsyncThunk('auth/GetLogin', async (request: LoginQrcodeRequest) => {
	const response = await api
		.post('/campos-login-qrcode', request)
		.then((r): LoginQrcodeResponse => {
			return r.data;
		})
		.catch(error => {
			return error.response.data;
		});
	return response;
});

export const DoLogin = createAsyncThunk('auth/DoLogin', async (request: any) => {
	const response = await api
		.post('/login-comanda', request)
		.then((r): LoginResponse => {
			return r.data;
		})
		.catch(error => {
			return error.response.data;
		});
	return response;
});

export const StatusSession = createAsyncThunk('auth/StatusSession', async (request: SessionStatusRequest) => {
	const response = await api
		.post('/status-sessao', request)
		.then((r): SessionStatusResponse => {
			return r.data;
		})
		.catch(error => {
			return error.response.data;
		});
	return response;
});
