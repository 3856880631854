import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import ModalInfo from '@/components/ModalInfo';
import { usePages } from '@/contexts/pages';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { GerFilters, PostSearch } from '@/services/search';
import { SearchOutlined } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slide, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';

type GroupType = {
	groupId: number;
	group: string;
	filters: {
		id: number;
		name: string;
	}[];
}[];

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchPage() {
	const dispatch = useAppDispatch();
	const { listFilters, resultSearch, requestError, loading } = useAppSelector((state) => state.search);
	const [groups, setGroups] = useState<GroupType>();
	const { openSearch, setOpenSearch, setOpenResultSearch } = usePages();
	const [fieldSearch, setFieldSearch] = useState('');
	const [filtersComplete, setFiltersComplete] = useState<any>({});
	const [filers, setFilers] = useState([]);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');

	function onDismiss() {
		setOpenSearch(false);
	}

	async function makeSearch() {
		await dispatch(PostSearch({ busca: fieldSearch, filtros: filers }));
	}

	useEffect(() => {
		if (resultSearch) {
			if (resultSearch.retorno) {
				if (resultSearch.conteudo) {
					setOpenResultSearch(true);
				} else {
					setSnackMsg('Nenhum resultado encontrado para sua pesquisa!');
					setShowSnack(true);
				}
			}
		}
	}, [resultSearch]);

	useEffect(() => {
		if (openSearch && !listFilters) {
			(async () => {
				await dispatch(GerFilters());
			})();
		}
	}, [openSearch]);

	useEffect(() => {
		if (requestError) {
			if (requestError !== 'Nenhum filtro encontrado') {
				setSnackMsg(requestError);
				setShowSnack(true);
			}
		}
	}, [requestError]);

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	useEffect(() => {
		if (listFilters) {
			const newGroups: GroupType = [];

			listFilters.filtros.forEach((filter) => {
				let addNew = false;

				newGroups.forEach((group) => {
					if (group.groupId !== filter.titulo_pai) {
						addNew = true;
					} else {
						addNew = false;
					}
				});

				if (addNew || newGroups.length < 1) {
					newGroups.push({
						groupId: filter.titulo_pai,
						group: filter.titulo,
						filters: [],
					});
				}
			});

			newGroups.forEach((group, index) => {
				listFilters.filtros.forEach((filter) => {
					if (group.groupId === filter.titulo_pai) {
						newGroups[index].filters.push({
							id: filter.id,
							name: filter.filtro,
						});
					}
				});
			});

			setGroups(newGroups);
		}
	}, [listFilters]);

	function addFilter(filterId: number, groupId: number) {
		const newFilters: any = { ...filtersComplete };

		if (groupId in newFilters) {
			if (newFilters[groupId] === filterId) {
				delete newFilters[groupId];
			} else {
				newFilters[groupId] = filterId;
			}
		} else {
			newFilters[groupId] = filterId;
		}

		setFiltersComplete(newFilters);
	}

	useEffect(() => {
		const arrFilter: any = [];
		Object.keys(filtersComplete).forEach(function (key) {
			var val = filtersComplete[key];
			arrFilter.push(val);
		});
		setFilers(arrFilter);
	}, [filtersComplete]);

	return (
		<Dialog fullScreen open={openSearch} onClose={onDismiss} TransitionComponent={Transition} id="search" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title="Busca" onDismiss={onDismiss} />
			<DialogContent>
				<section className="p-4">
					<form className="form">
						<div className="formInput">
							<TextField
								fullWidth
								className="searchInput"
								label="Buscar pelo produto..."
								value={fieldSearch}
								variant="outlined"
								onChange={(event) => setFieldSearch(event.currentTarget.value)}
							/>
							<Button className="inputBtn" onClick={() => makeSearch()}>
								<SearchOutlined />
							</Button>
						</div>
						{groups?.map((group) => (
							<FormControl key={group.groupId} className="groupContent">
								<FormLabel id={`group-label-${group.groupId}`} className="groupTitle">
									{group.group}
								</FormLabel>
								<RadioGroup name={`group-label-${group.groupId}`} className="groupRadio" value={filtersComplete[group.groupId] || 0}>
									<div className="hidden">
										<FormControlLabel label="" value={0} control={<Radio className="hidden" />} className="hidden" />
									</div>
									{group.filters?.map((filter) => (
										<FormControlLabel
											key={filter.id}
											value={filter.id}
											control={<Radio onClick={() => addFilter(filter.id, group.groupId)} />}
											label={filter.name}
											className="optionalRadio"
										/>
									))}
								</RadioGroup>
							</FormControl>
						))}
					</form>
				</section>
			</DialogContent>

			<div className="footerBlock">
				<Button variant="contained" className="footerBtn" onClick={() => makeSearch()} disabled={loading}>
					{loading ? 'Buscando produtos...' : 'Aplicar'}
				</Button>
			</div>
			<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
		</Dialog>
	);
}
