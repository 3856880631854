import { createSlice } from '@reduxjs/toolkit';
import {
  DeleteAddress,
  GetAddress,
  GetCustomerData,
  PostAddress,
} from '@/services/delivery/user';
import { CustomerResponse } from '@/models/delivery/customer.response';
import { RegisterAddressResponse } from '@/models/delivery/registerAddress.response';
import { GetAddressResponse } from '@/models/delivery/getAddress.response';
import { DeleteAddressResponse } from '@/models/delivery/deleteAddress.response';

const initialState = {
  customer: <CustomerResponse | null>null,
  loadingCustomer: false,
  registerAddress: <RegisterAddressResponse | null>null,
  address: <GetAddressResponse | null>null,
  deleteAddress: <DeleteAddressResponse | null>null,
  loadingPostAddress: false,
  loadingGetAddress: false,
  loadingDelete: false,
  loading: false,
  requestError: <null | string>null,
};

const userDeliverySlice = createSlice({
  name: 'userDelivery',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCustomerData.pending, (state) => {
        state.requestError = null;
        state.customer = null;
        state.loadingCustomer = true;
      })
      .addCase(GetCustomerData.fulfilled, (state, { payload }) => {
        state.loadingCustomer = false;
        if (payload) {
          if (payload.retorno) {
            state.requestError = null;
            state.customer = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(PostAddress.pending, (state) => {
        state.requestError = null;
        state.registerAddress = null;
        state.loadingPostAddress = true;
      })
      .addCase(PostAddress.fulfilled, (state, { payload }) => {
        state.loadingPostAddress = false;
        if (payload) {
          if (payload.retorno) {
            state.registerAddress = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(GetAddress.pending, (state) => {
        state.requestError = null;
        state.address = null;
        state.loadingGetAddress = true;
      })
      .addCase(GetAddress.fulfilled, (state, { payload }) => {
        state.loadingGetAddress = false;
        if (payload) {
          if (payload.retorno) {
            state.address = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      })
      .addCase(DeleteAddress.pending, (state) => {
        state.requestError = null;
        state.deleteAddress = null;
        state.loadingDelete = true;
      })
      .addCase(DeleteAddress.fulfilled, (state, { payload }) => {
        state.loadingDelete = false;
        if (payload) {
          if (payload.retorno) {
            state.deleteAddress = payload;
          } else {
            state.requestError = payload.mensagem;
          }
        }
      });
  },
});

export const { reset } = userDeliverySlice.actions;
export default userDeliverySlice.reducer;
