import ModalInfo from '@/components/ModalInfo';
import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';

type CompType = {
  time: string;
  pixKey: string;
};

export default function ContentComp({ time, pixKey }: CompType) {
  const [showSnack, setShowSnack] = useState(false);

  function onCopy() {
    navigator.clipboard.writeText(pixKey || '');
    setShowSnack(true);
  }

  function handleAlert(state: boolean) {
    setShowSnack(state);
  }

  return (
    <div>
      <p className="pixTitle">Código gerado com sucesso:</p>
      <p className="pixDesc">
        1- Copie o código pix abaixo pelo botão <span>Copiar Código:</span>
      </p>
      <br />
      <p className="pixDesc">
        2- Abra o aplicativo do banco que tenha o PIX habilitado e utilize a
        opção <span>Copia e Cola:</span>
      </p>
      <br />
      <p className="pixDesc">3- Cole o código copiado e faça o pagamento</p>
      <br />
      <div className="timeBlock">
        <p className="timeTitle">O tempo para você pagar acaba em:</p>
        <p className="time">{time}</p>
      </div>
      <TextField
        value={pixKey}
        fullWidth
        className="blockKey"
        label="Código pix"
        variant="outlined"
        disabled={true}
        onChange={() => {
          null;
        }}
      />
      <Button variant="contained" className="btnPix" onClick={onCopy}>
        Copiar código
      </Button>
      <ModalInfo open={showSnack} handler={handleAlert} message='Código copiado com sucesso!'/>     
    </div>
  );
}
