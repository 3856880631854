import ArrowIcon from '@/assets/svg/Arrow';
import CommonClears from '@/helpers/clears/common.clear';
import CommonMask from '@/helpers/masks';
import { useAppSelector } from '@/redux/hooks';
import { TextField } from '@mui/material';
import React from 'react';

type FormType = {
  handleChange(
    value: string | number,
    fieldName: string,
    typeForm: 'personal' | 'card'
  ): void;
  formCard: {
    number: string;
    cvc: string;
    month: string;
    year: string;
  };
  setFormCard: React.Dispatch<
    React.SetStateAction<{
      number: string;
      cvc: string;
      month: string;
      year: string;
    }>
  >;
  months: string[];
  years: number[];
  cardError: {
    number: string;
    cvc: string;
    month: string;
    year: string;
  };
};

export default function FormCard({
  handleChange,
  formCard,
  months,
  years,
  cardError,
  setFormCard,
}: FormType) {
  const { loading } = useAppSelector((state) => state.payment);

  return (
    <div className="methodsBody">
      <TextField
        value={CommonMask.card(formCard.number)}
        fullWidth
        error={!!cardError.number}
        helperText={cardError.number}
        required
        className="field"
        label="Número do cartão"
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        inputMode="numeric"
        variant="outlined"
        disabled={loading}
        onChange={(event) => {
          if (event.target.value.length <= 19) {
            const value = CommonClears.clearCard(event.target.value);
            handleChange(value, 'number', 'card');
          }
        }}
      />
      <div className="formRow">
        <div className="formValidate">
          <div className={`blockSelect ${!!cardError.month && 'error'}`}>
            <div className="selectIcon">
              <ArrowIcon />
            </div>
            <select
              className="select"
              value={formCard.month}
              onChange={(event) =>
                setFormCard({ ...formCard, month: event.target.value })
              }
            >
              <option value="">Mês *</option>
              {months.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className={`blockSelect ${!!cardError.year && 'error'}`}>
            <div className="selectIcon">
              <ArrowIcon />
            </div>
            <select
              className="select "
              value={formCard.year}
              onChange={(event) =>
                setFormCard({ ...formCard, year: event.target.value })
              }
            >
              <option value="">Ano *</option>
              {years.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex-1">
          <TextField
            value={formCard.cvc}
            fullWidth
            error={!!cardError.cvc}
            required
            className="field"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            inputMode="numeric"
            label="CVC"
            variant="outlined"
            disabled={loading}
            onChange={(event) => {
              const value = event.target.value;
              if (value.length <= 3) {
                handleChange(value, 'cvc', 'card');
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
