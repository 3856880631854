import { useAppSelector } from '@/redux/hooks';
import React, { useEffect, useState } from 'react';
import FooterComp from './components/Footer';
import OptionalSize from './components/OptionalSize';
import OptionalRadio from './components/OptionalRadio';
import OptionalCheckbox from './components/OptionalCheckbox';
import OptionalQtd from './components/OptionalQtd';
import BannerComp from './components/Banner';
import { Box, Button, Dialog, DialogContent, Slide, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CartType } from '@/models/cartModel';
import { useMain } from '@/contexts/main';
import { usePages } from '@/contexts/pages';
import { isMobile } from 'react-device-detect';
import { ListProductsResponse } from '@/models/listProducts.response';
import ModalThumb from '@/components/ModalThumb';
import { TransitionProps } from '@mui/material/transitions';
import CommonMask from '@/helpers/masks';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductPage() {
	const { client } = useAppSelector(state => state.auth);
	const [productQtd, setProductQtd] = useState(1);
	const [totValue, setTotValue] = useState(0);
	const [variableProduct, setVariableProduct] = useState<any>({ preco: 0 });
	const [optionals, setOptionals] = useState<CartType['items'][0]['options']>([]);
	const { qrcode, storageLogin, product, isStoreOpen } = useMain();
	const { openProduct, setOpenProduct, setOpenCart } = usePages();
	const allAccess = client?.config_cliente?.comanda_sem_qrcode ? true : client && !client.config_cliente.somente_cardapio ? !!((qrcode || storageLogin) && isMobile && isStoreOpen) && !product?.somente_consulta : false;
	const [fieldObs, setFieldObs] = useState('');
	const [currentImg, setCurrentImg] = useState({
		src: '',
		title: '',
		price: '',
	});
	const [openModal, setOpenModal] = useState(false);

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleOpenModal = ({ src, title, price }: { src: string; title: string; price: string }) => {
		setCurrentImg({
			src,
			title,
			price,
		});
		setOpenModal(true);
	};

	function onDismiss() {
		const scroll = document.querySelector('#product .MuiDialogContent-root');
		setProductQtd(1);
		setTotValue(0);
		setOptionals([]);
		setFieldObs('');
		setOpenProduct(false);
		if (scroll) {
			scroll.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

	function scrollToNextItem(indexNext: number) {
		const scroll = document.querySelector('#product .MuiDialogContent-root');
		const nextItem: any = document.querySelector(`#product [data-listItem="${indexNext}"]`);
		if (nextItem) {
			const nextItemPostion = nextItem.offsetTop;

			scroll?.scrollTo({
				top: nextItemPostion - 50,
				behavior: 'smooth',
			});
		}
	}

	useEffect(() => {
		if (openProduct && product) {
			let totOptional = 0;
			optionals.forEach(optional => {
				totOptional += optional.value * optional.qtd;
			});
			setTotValue(productQtd * ((variableProduct.preco_promocao > 0 ? variableProduct.preco_promocao : variableProduct.preco) + totOptional));
		}
	}, [productQtd, variableProduct, optionals]);

	useEffect(() => {
		if (product) {
			setVariableProduct(product.unidade_medida[0]);
			setTotValue(product.unidade_medida[0].preco_promocao > 0 ? product.unidade_medida[0].preco_promocao : product.unidade_medida[0].preco);
		}
	}, [openProduct]);

	function changeOptionals(newOptional: CartType['items'][0]['options'][0], catId: number, maxQtd: number) {
		const newOptionals: CartType['items'][0]['options'] = [...optionals];
		if (optionals.length < 1) {
			newOptionals.push({ ...newOptional });
		} else {
			let haveSame = false;
			optionals.forEach((currentOptional, index) => {
				if (catId === currentOptional.catId && maxQtd === 1) {
					haveSame = true;
					newOptionals[index] = { ...newOptional };
				}
			});

			if (!haveSame) {
				newOptionals.push({ ...newOptional });
			}
		}
		setOptionals(newOptionals);
	}

	function changeOptionalsCheckbox(newOptionalList: CartType['items'][0]['options'], catId: number) {
		if (optionals) {
			const newOptionals: CartType['items'][0]['options'] = [...optionals];
			if (optionals.length < 1) {
				newOptionals.push({ ...newOptionalList[0], catId });
			} else {
				optionals.filter(v => {
					if (v.catId === catId) {
						newOptionals.splice(newOptionals.indexOf(v), 1);
					}
				});

				newOptionalList.forEach(newItem => {
					newOptionals.push({ ...newItem, catId });
				});
			}
			setOptionals(newOptionals);
		}
	}

	function changeOptionalsQtd(newOptionalList: CartType['items'][0]['options'], catId: number) {
		if (optionals) {
			const newOptionals: CartType['items'][0]['options'] = [...optionals];
			if (optionals.length < 1) {
				newOptionals.push({ ...newOptionalList[0], catId });
			} else {
				optionals.filter(v => {
					if (v.catId === catId) {
						newOptionals.splice(newOptionals.indexOf(v), 1);
					}
				});
				newOptionalList.forEach(newItem => {
					newOptionals.push({ ...newItem, catId });
				});
			}
			setOptionals(newOptionals);
		}
	}

	function renderLabelOptional(min: number, max: number) {
		let label = '';

		if (min > 0 && max > 1) {
			label = `Escolha de ${min} a ${max} opções`;
		} else if (min > 0 && max === 0) {
			label = `Escolha no mínimo ${min} opções`;
		} else if (min === 1 && max === 1) {
			label = `Escolha 1 opção`;
		} else if (min === 0 && max > 0) {
			label = `Escolha até ${max} opções`;
		}

		return label;
	}

	function renderOptional(option: ListProductsResponse['conteudo'][0]['opcionais'][0], index: number) {
		if (option.qtde_por_item) {
			return <OptionalQtd option={option} changeOptionals={changeOptionalsQtd} allAccess={allAccess} scrollToNextItem={scrollToNextItem} index={index} handleOpenModal={handleOpenModal} />;
		} else if (option.qtde_max_selecao === 1 && option.qtde_min_selecao === 1) {
			return <OptionalRadio option={option} changeOptionals={changeOptionals} allAccess={allAccess} scrollToNextItem={scrollToNextItem} index={index} handleOpenModal={handleOpenModal} />;
		} else {
			return <OptionalCheckbox option={option} changeOptionals={changeOptionalsCheckbox} allAccess={allAccess} scrollToNextItem={scrollToNextItem} index={index} handleOpenModal={handleOpenModal} />;
		}
	}

	return (
		<>
			<Dialog fullScreen open={openProduct} onClose={onDismiss} TransitionComponent={Transition} id="product" scroll="paper" className="customDialog" disableScrollLock>
				<Button variant="contained" className="btnDown" onClick={onDismiss}>
					<KeyboardArrowDownIcon />
				</Button>
				<DialogContent>
					<BannerComp product={product} />
					<div className="p-4">
						<h3 className="title">{product?.nome}</h3>
						<ul className="tags">
							{product?.tags?.map(tag => (
								<li className="tagItem" key={tag.nome} style={{ borderColor: `#${tag.cor}`, color: `#${tag.cor}` }}>
									{tag.nome}
								</li>
							))}
						</ul>
						<Box className="descriptionAndPrice">
							<p className="descriptionTitle">Descrição</p>
							<p className="descriptionTitle">
								{product?.preco_principal.texto ? 'A partir de R$ ' : 'R$ '}

								{product
									? CommonMask.currency(
											CommonMask.currency(
												product?.unidade_medida[0]?.preco_promocao > 0
													? product?.unidade_medida[0]?.preco_promocao?.toFixed(2)?.toString()
													: product?.preco_principal?.preco?.toFixed(2)?.toString()
													? product?.preco_principal?.preco?.toFixed(2)?.toString()
													: '',
											),
									  )
									: ''}
							</p>
						</Box>
						<div className="description">{product?.descricao}</div>
						{product?.unidade_medida && product.unidade_medida.length > 1 && <OptionalSize setVariableProduct={setVariableProduct} product={product} allAccess={allAccess} scrollToNextItem={scrollToNextItem} />}
						{product?.opcionais.map((option, index) => (
							<div key={option.titulo + index} className="optional" data-listItem={index}>
								<div className="optionalHeader ">
									<div className="optionalHeaderContent">
										<h4 className="optionalHeaderTitle">{option.titulo}</h4>
										<p className="optionalHeaderSelection">{renderLabelOptional(option.qtde_min_selecao, option.qtde_max_selecao)}</p>
									</div>
									{(option.selecao_obg || option.qtde_min_selecao > 0) && <div className="optionalHeaderRequired">Obrigatório</div>}
								</div>
								<div className="optionalBody">{renderOptional(option, index)}</div>
							</div>
						))}
						{allAccess && !client?.config_cliente?.oculta_obs_itens && (
							<div className="optional" data-listItem={product?.opcionais.length}>
								<div className="optionalHeader ">
									<div className="optionalHeaderContent">
										<h4 className="optionalHeaderTitle">Alguma observação?</h4>
									</div>
									<div className="optionalHeaderRequired">{fieldObs.length}/140</div>
								</div>
								<TextField fullWidth multiline className="optionalObs" minRows={2} value={fieldObs} variant="outlined" onChange={event => (event.currentTarget.value.length <= 140 ? setFieldObs(event.currentTarget.value) : null)} />
							</div>
						)}
					</div>
				</DialogContent>
				{allAccess && (
					<FooterComp
						integrationCode={variableProduct?.codigo_integracao || product?.codigo_integracao}
						product={product}
						totValue={totValue}
						productQtd={productQtd}
						setProductQtd={setProductQtd}
						onDismiss={onDismiss}
						setOpenCart={setOpenCart}
						idUnity={variableProduct.id || 0}
						unityValue={(variableProduct.preco_promocao > 0 ? variableProduct.preco_promocao : variableProduct.preco) || 0}
						variableName={variableProduct.nome}
						options={optionals}
						fieldObs={fieldObs}
					/>
				)}
				<ModalThumb img={currentImg} open={openModal} handleClose={handleCloseModal} />
			</Dialog>
		</>
	);
}
