import { usePages } from '@/contexts/pages';
import { ListProductsResponse } from '@/models/listProducts.response';
import { Button } from '@mui/material';
import React from 'react';
import ImageNotFound from '@/assets/img/ImageNotFound.png';
import { useAppSelector } from '@/redux/hooks';
import CommonMask from '@/helpers/masks';
import UseProgressiveImage from '@/helpers/progressiveImage';
import { SearchClienteResponse } from '@/models/client.response';
import { SearchClienteResponse as SearchClientDelyveryModel } from '@/models/delivery/client.response';

type ProductsType = {
	product: ListProductsResponse['conteudo'][0];
	setCurrentProduct: React.Dispatch<React.SetStateAction<ListProductsResponse['conteudo'][0] | null>>;
	client: SearchClienteResponse | SearchClientDelyveryModel | null;
};

export default function ProductItem({ product, setCurrentProduct, client }: ProductsType) {
	const { setOpenProduct } = usePages();
	// const { client } = useAppSelector((state) => state.auth);

	function renderDesc(desc: string) {
		let newDesc = desc;

		if (client) {
			if (!client.config_cliente.descricao_item_completa && desc.length > client.config_cliente.qtde_caracteres_descricao_item) {
				newDesc = `${desc.substring(0, client?.config_cliente.qtde_caracteres_descricao_item)}...`;
			}
		}

		return newDesc;
	}

	return (
		<Button
			className="productItem"
			onClick={() => {
				setCurrentProduct(product);
				setOpenProduct(true);
			}}>
			{product.imagens.map((img) => {
				if (img.principal) {
					return (
						<div
							key={img.ordem}
							style={{
								backgroundImage: `url(${img.imagem ? UseProgressiveImage(img.imagem) : ImageNotFound.src})`,
							}}
							className="productImg"></div>
					);
				}
			})}
			<div className="flex-1">
				<p className="productName">{product.nome}</p>
				<p className="productDesc">{product.descricao && renderDesc(product.descricao)}</p>
				<p className={`productPrice ${product.unidade_medida[0].preco_promocao > 0 && 'productPrice--withPromo'}`}>
					{product.preco_principal.texto ? 'A partir de R$ ' : 'R$ '}

					{CommonMask.currency(CommonMask.currency(product.preco_principal.preco.toFixed(2).toString()))}
				</p>
				{product.unidade_medida[0].preco_promocao > 0 && (
					<p className="productPrice productPrice--promo">R$ {CommonMask.currency(CommonMask.currency(product.preco_principal.preco_promocao.toFixed(2).toString()))}</p>
				)}
			</div>
		</Button>
	);
}
